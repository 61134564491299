<template>
  <v-dialog v-model="isProcessing" persistent max-width="400">
    <v-card>
      <v-card-title class="headline"
        >{{ uploading ? "Uploading" : "Downloading" }} File</v-card-title
      >
      <v-card-text>
        <v-progress-linear
          :value="progress"
          :indeterminate="progress > 0 ? false : true"
          height="20"
          color="blue"
        >
          <div class="text-center" v-if="progress > 0">{{ progress }}%</div>
        </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isProcessing: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    uploading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.text-center {
  color: white;
}
</style>
